@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
html, body {
	padding: 0;
	margin: 0;
	display: flex;
	width: 100%;
	font-family: 'Roboto', sans-serif;
	box-sizing: border-box;
	background-color: #f0f0f0;
}

* {
	box-sizing: border-box;
}

*:focus {
	outline: none !important;
}

.input-group {
	display: flex;
	flex-direction: column-reverse;
}

.input-group input, .input-group select, .input-group textarea {
	padding: 10px;
	border: solid 1px #ccc;
	border-radius: 5px;
}

.input-group input:required + label, .input-group select:required + label {
	display: flex;
	align-items: center;
}

.input-group input:required + label::after, .input-group select:required + label::after {
	display: inline-block;
	content: "*";
	color: #e22121;
	font-size: 10px;
	padding-bottom: 8px;
	padding-left: 2px;
	line-height: 18px;
}

#root, .App {
  width: 100%;
  height: 100%;
}

.App {
	padding: 55px 0;
}

div.header {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

div.header div.topMenu {
	position: fixed;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	z-index: 100;
}

div.topMenu div.container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	max-width: 1124px;
	padding: 10px 0;
}

div.topMenu div.container h1 {
	font-size: 14pt;
	font-weight: 900;
	padding: 10px;
	margin: 0;
	color: white;
}

div.header div.banner {
	margin-top: 60px;
	width: 100%;
	height: 250px;
	display: flex;
}

div.header div.banner img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.searchBtn {
	display: flex;
	grid-gap: 10px !important;
	gap: 10px !important;
	padding: 5px !important;
	background-color: transparent;
	border: none;
	margin: 0;
	cursor: pointer;
	color: white;
	font-size: 14pt;
	font-weight: 500;
	justify-content: center;
}

#cartIcon {
	display: flex;
	position: relative;
}

#cartIcon .cartCounter {
	position: absolute;
	top: 2px;
	right: -2px;
	background: red;
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	font-size: 0.8em;
}

div.body {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	max-width: 1124px;
	margin: auto;
}

button.cartBtn {
	display: none;
	position: fixed;
	bottom: 0;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	color: white;
	font-size: 25px;
	padding: 10px;
	z-index: 100;
	border: none;
	cursor: pointer;
}

button.cartBtn svg {
	margin-right: 10px;
}

div.modalContent{
	position: fixed;
	display: none;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(25, 25, 25, 0.4);
	z-index: 100;
}

div.modalContent.active {
	display: flex !important;
}

div.modalContent .modal {
	width: 100%;
	max-height: 95vh;
	margin: 10px;
	max-width: 550px;
	position: relative;
	background-color: white;
	border-radius: 5px;
	overflow: hidden;
}

#modalAdd {
	z-index: 200;
}

.modal button.close {
	position: absolute;
	background: transparent;
	border: none;
	cursor: pointer;
	right: 10px;
	top: 10px;
	margin: 0 !important;
	padding: 0;
}

.footer {
	display: flex;
	justify-content: center;
	border: solid 1px #ccc;
	background: white;
	margin: 0;
	margin-top: 25px;
}

.footer .footerCtt {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	max-width: 1124px;
	padding: 15px 0;
	grid-gap: 10px;
}


.footer .footerCtt h1 {
	padding: 10px;
	border-radius: 5px;
	margin: 0;
	justify-content: center;
	color: white;
	font-size: 16pt;
	margin-bottom: 15px;
}

.footer .footerCtt p {
	padding: 15px;
}

.footer .footerCtt div {
	display: flex;
	flex-direction: column;
}

.footer .footerCtt img {
	width: 100%;
	min-height: 30px;
}

table.itens {
	border-collapse: collapse;
}

table.itens thead {
	color: white;
}

table.itens thead th {
	border: solid 1px #222;
	padding: 5px;
}

table.itens tbody {
	color: #555;
}

table.itens tbody td {
	padding: 5px;
	border: solid 1px #ccc;
}

button {
	padding: 8px 10px;
	border-radius: 5px;
	border: none;
}

@media (max-width: 1200px) {
	div.body{
		padding: 15px;
	}

	.footer .footerCtt {
		grid-template-columns: 1fr;
		margin-bottom: 50px;
	}

	button.cartBtn {
		display: flex;
	}
}

.InfoHeader {
    display: flex;
    width: 100%;
    margin: 20px;
    margin-top: -80px;
    z-index: 1;
    padding: 10px;
    justify-content: center;
    align-items: center;
}

.InfoHeader .InfoContainer {
    width: 100%;
    max-width: 1124px;
    display: flex;
    padding: 20px;
    background-color: white;
    flex-direction: row;
    border-radius: 5px;
    border: solid 1px #ccc
}

.logo {
    width: 150px !important;
    height: 150px !important;
    border: solid 1px #ccc;
    border-radius: 50%;
    overflow: hidden;
    border: solid 1px #ccc;
    margin-right: 45px;
}

.logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

div.info {
    max-width: 890px;
}

.infoSectors h1 {
    font-size: 14pt;
    color: #666;
}

.infoSectors {
    display: grid;
    font-size: 11pt;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
}

.whatsapp {
    grid-column-start: 4;
}

.whatsapp .wp-button {
    width: 100%;
}

.whatsapp .wp-button a {
    display: flex;
    width: 100%;
    background-color: #efefef;
    width: auto;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border-radius: 5px;
    color: black;
    text-decoration: none;
}

.whatsapp .wp-button a svg {
    margin-right: 10px;
}

a.switchButton {
    display: flex;
    align-items: center;
    margin-top: 5px;
    padding: 10px 12px;
    color: white;
    font-size: 22px;
    border-radius: 5px;
    text-decoration: none;
}

a.switchButton svg {
    margin-right: 10px;
}

@media (max-width: 1400px){
    .InfoContainer{
        align-items: center;
        justify-content: center;
        margin: 0 10px;
    }
    .infoSectors {
        grid-template-columns: repeat(3, 1fr) !important;
    }
    .timeShowed .whatsapp {
        grid-column-start: 3;
    }

    .whatsapp {
        grid-column-start: 1;
    }
}

@media (max-width: 1200px) {
    .logo {
        margin: 0;
        margin-bottom: 15px;
    }
    .InfoHeader .InfoContainer {
        flex-direction: column !important;
    }

    .infoSectors {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .brandMessage h1 {
        display: none;
    }

    .timeShowed .whatsapp {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .whatsapp {
        grid-column-start: 2;
    }
}
@keyframes spin {
	0% {transform: rotate(0deg)}
	100% {transform: rotate(720deg)}
}

div.containerGen {
	width: 100%;
}

div.list, div.category {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 10px;
}

div.category div.catItem {
	display: grid;
	grid-template-columns: 80px auto;
	grid-gap: 15px;
	overflow-wrap: break-word;
	line-break: anywhere;
	padding: 10px;
	background: white;
	border-radius: 5px;
	cursor: pointer;
	border: 1px solid #ccc;
	align-items: center;
}

div.category div.catItem div.image {
	width: 80px;
	height: 80px;
	margin-right: 15px;
	border-radius: 50%;
	overflow: hidden;
}

div.category div.catItem h1 {
	color: #888;
}

div.category div.catItem div.image img {
	width: 100%;
	height: 100%;
}

div.list div.item {
	position: relative;
	min-height: 160px;
	display: flex;
	flex-direction: row;
	padding: 15px;
	border-radius: 5px;
	border: solid 1px #ccc;
	background-color: white;
	cursor: pointer;
	flex-flow: row;
	justify-content: stretch;
	padding-bottom: 70px;
}

div.list div.item:hover {
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

div.list div.item h1 {
	font-size: 14pt;
}

div.list div.item h2 {
	font-size: 14pt;
}

div.list div.item .image {
	width: 60px;
	height: 60px;
}

div.list div.item .image img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

div.list div.item .bodyItem {
	flex-grow: 1;
}
div.list div.item button.add {
	right: 10px;
	cursor: pointer;
	margin: 0;
	padding: 10px;
	background-color: transparent;
	border: none;
}

div.item .image {
	margin-right: 15px;
	min-width: 60px !important;
}

div.item .footerItem {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 10px;
	align-items: center;
}

div.item .footerItem .out_of_stock {
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 900;
	font-size: 1.2em;
	color: #aaa;
	padding: 15px;
	width: 100%;
}

div.item .footerItem .price{
	font-size: 16pt;
	font-weight: 900;
	color: #888;
	margin: 0;
}

div.modalContent div.headerModal {
	position: relative;
	color: white;
	padding: 18px;
	font-weight: 900;
	z-index: 0 !important;
	padding-right: 50px;
}

div.modalContent div.body{
	display: flex;
	flex-direction: column;
}

div.modalContent div.body div.image {
	display: flex;
	width: 100%;
	height: 220px;
}

div.modalContent div.body div.image img {
	width: 100%;
	object-fit: scale-down;
}

.modal .contentBodyProduct {
	max-height: 80vh;
	overflow-y: scroll;
}

div.qtdAndTotalSets {
	display: flex;
	width: 100%;
	text-align: center;
	vertical-align: middle;
	justify-content: space-between;
	font-size: 22px;
}

div.qtdAndTotalSets .totalSet {
	display: flex;
	justify-content: center;
	align-items: center;
}

div.modalContent div.qtdSet{
	display: flex;
	flex-direction: row;
}

div.modalContent .qtdContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	font-size: 22px;
}

div.modalContent div.qtdSet button {
	display: flex;
	justify-content: center;
}

div.modalContent button.minusPlus {
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 10px;
}

div.modalContent .obsSet {
	display: flex;
	width: 100%;
}

div.modalContent .obsSet textarea {
	margin-top: 15px;
	padding: 10px;
	width: 100%;
	resize: vertical;
	border-radius: 5px;
	border: solid 1px #ccc;
	font-family: 'Roboto', sans-serif;
	min-height: 60px;
}

div.modalContent .obsSet label {
	margin-top: 15px;
}

div.modalFooter {
	display: flex;
	justify-content: space-between;
	padding: 15px;
	margin: 0;
}

div.modalFooter button {
	display: flex;
	padding: 10px;
	float: right;
	cursor: pointer;
	color: white;
	border: none;
	border-radius: 5px;
}

div.modalFooter button.cancel{
	background-color: red;
}

div.modalFooter button.confirm {
	background-color: green;
}

p.estoque {
	font-size: 14pt;
	font-weight: 900;
	margin: 0;
	color: #bbb;
}

div.list div.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-column-start: 1;
	grid-column-end: 4;
}

div.list div.loaderContainer .loader {
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 400px;
	border: solid 4px transparent;
	animation: spin 0.8s infinite ease-in-out;
}

div.list div.loaderContainer .loader.hidden{
	display: none !important;
}

div.errorChargingItems {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-column-start: 1;
	grid-column-end: 4;
}

#modalAdd {
	align-items: flex-start;
}

div.errorChargingItems svg {
	margin-right: 15px;
}

@media (max-width: 1200px) {

	div.list, div.category {
		grid-template-columns: 1fr 1fr
	}

}

@media (max-width: 600px){
	div.list, div.category{
		grid-template-columns: 1fr;
	}

	div.list div.loaderContainer{
		grid-column-start: 1;
		grid-column-end: 1;
	}
}

.containerDiv {
    height: 95vh;
    padding-top: 48px;
	margin-top: -48px;
	min-width: 350px;
}

#modalCart .modal .cartContainer {
	background: #e1e1e1;
    height: 100%;
    overflow-y: scroll !important;
}

#cartTitle {
    z-index: 120;
}

.backToStoreBtn {
    cursor: pointer;
    border: none;
    font-size: 12pt;
    color: white;
    margin-top: 10px;
}

.cart {
    display: flex;
    width: 35%;
    border-radius: 10px;
    border: solid 1px #ccc;
    overflow: hidden;
    flex-flow: column;
}

.userContainer {
    display: flex;
    margin: 10px;
    border: solid 1px #ccc;
    border-radius: 5px;
    cursor: pointer;
}

.userContainer .usernameTitle {
    display: flex;
    align-items: center;
    font-size: 25px;
    margin: 10px;
    vertical-align: middle;
    margin: 20px;
}

.userContainer .imageUser {
    margin: 10px;
    width: 80px;
    height: 80px;
    border: solid 1px #ccc;
    border-radius: 400px;
    overflow: hidden;
}

.userContainer .imageUser img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px;
    width: 100%;
    height: 100%;
}

.react-tabs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.react-tabs ul {
    width: unset;
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    justify-content: center;
    align-items: center;
}

.react-tabs ul .listContainer {
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    border: solid 1px #ccc;
    overflow: hidden;
}

.react-tabs ul li {
    padding: 6px 10px;
    cursor: pointer;
}

.react-tabs ul li.react-tabs__tab--selected {
    color: white;
}

.react-tabs .address {
    display: flex;
    flex-direction: column;
}

.notShown {
    display: none;
}

.containerQtd {
	display: grid;
	grid-template-columns: 22px minmax(80px, 140px) 22px;
	grid-gap: 4px;
	justify-content: center;
}

.containerQtd div, .containerQtd input {
	padding: 2px 8px;
	font-size: 22px;
	width: 100%;
}

.containerQtd input {
	border: solid 1px #ccc;
	border-radius: 5px;
	color: #888
}

.containerQtd button {
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
}

.spanObsDetail {
    display: block;
    font-size: 14px;
    text-align: left;
    margin-top: -5px;
    margin-bottom: 10px;
    width: 100%;
    font-weight: 300;
}

.usersContainer {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;
}

.containerUsersPad {
    padding-top: 55px;
    margin-top: -55px;
    height: 90vh;
}

.backToStoreBtn {
	padding: 10px;
	border-radius: 5px;
}

#CartTabs ul {
	display: flex;
	padding: 0;
	margin: 0;
	margin-top: 10px;
}

#CartTabs ul li.react-tabs__tab {
	display: flex;
	flex-direction: row;
	grid-gap: 10px;
	gap: 10px;
}

#CartTabs ul li.react-tabs__tab span {
	display: none;
	justify-content: center;
	padding: 0;
	color: white;
	overflow: hidden;
	font-size: 20px;
}

#CartTabs ul li.react-tabs__tab--selected span {
	display: flex !important;
}

#CartTabs .CartTabPanel {
	display: flex;
	flex-direction: column;
	padding: 10px;
}

#CartTabs ul.items {
	display: flex;
	flex-direction: column;
	grid-gap: 10px;
	gap: 10px;
	width: 100%;
}

#CartTabs ul.items li.cartCard {
	position: relative;
	width: 100%;
	background: white;
	box-shadow: 0 0 5px #ccc;
	border-radius: 5px;
	padding: 10px 0;
	cursor: unset;
}

#CartTabs ul.items li.cartCard .upperSection {
	display: grid;
	grid-template-columns: 90px 1fr;
	align-items: center;
	width: 100%;
	grid-gap: 10px;
	padding: 10px;
	padding-right: 30px;
	border-bottom: solid 1px #ccc;
}

#CartTabs ul.items li.cartCard .upperSection img {
	display: flex;
	object-fit: cover;
	width: 100%;
}

#CartTabs ul.items li.cartCard .prices {
	display: flex;
	justify-content: space-between;
	padding: 10px;
	padding-bottom: 0;
	font-size: 15px;
}

#CartTabs ul.items li.cartCard .prices span {
	font-size: 22px;
	padding: 10px;
}

#CartTabs ul.items li.cartCard .prices .value {
	display: flex;
	font-weight: 800;
}

#CartTabs ul.items li.cartCard img {
	height: 90px;
}

#CartTabs ul.items li.cartCard button.delete {
	display: flex;
	border: none;
	cursor: pointer;
	position: absolute;
	top: 5px;
	right: 0;
}

#CartTabs ul.react-tabs__tab-list li.react-tabs__tab svg#moneyBill {
	fill: transparent;
	stroke-width: 1cm;
	stroke-opacity: 100%;
	stroke: #000;
}

#CartTabs ul.react-tabs__tab-list li.react-tabs__tab--selected svg#moneyBill {
	fill: transparent;
	stroke-width: 1cm;
	stroke-opacity: 100%;
	stroke: #fff;
}

#CartTabs .emailLine {
	display: grid;
	grid-template-columns: minmax(200px, 400px) minmax(120px, 150px);
	align-items: flex-end;
	grid-gap: 10px;
}

#CartTabs .cepLine {
	display: grid;
	grid-template-columns: minmax(90px, 150px) minmax(200px, 400px);
	align-items: flex-end;
	grid-gap: 10px;
}

#CartTabs .compositions {
	display: flex;
	flex-direction: column;
	padding: 10px;
	border-bottom: solid 1px #ccc;
}

#CartTabs .compositions .composition {
	display: grid;
	grid-template-columns: 3fr minmax(10px, 30px) 1fr;
	padding: 10px;
}

#CartTabs .compositions .composition span.compositionName {
	text-align: left;
}

#CartTabs .compositions .composition span.totalComposition {
	text-align: right;
}

.buttonsCartTabs {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.buttonsCartTabs .obsContainer {
	padding: 0 10px;
}

.buttonsCartTabs .total {
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding: 10px;
	font-size: 28px;
	font-weight: 800;
	margin-top: 10px;
	grid-gap: 10px;
	color: #888;
}

.buttonsCartTabs .total span {
	font-size: 28px;
	text-align: right;
}

.buttonsCartTabs .btns {
	display: flex;
	justify-content: space-between;
	padding: 10px;
}

.buttonsCartTabs .btns button {
	cursor: pointer;
	font-size: 20px;
	color: white;
	border: none;
	border-radius: 5px;
	padding: 8px 14px;
}

ul.tabsRetirada {
	display: flex;
	justify-content: center;
	align-items: center;
}

ul.tabsRetirada div.containerOptionsRetirada {
	display: flex;
	border: solid 1px #ccc;
	border-radius: 5px;
	background: white;
	overflow: hidden;
	padding: 0;
}

ul.tabsRetirada div.containerOptionsRetirada li {
	border: none;
	border-radius: 0;
	margin: 0;
	padding: 8px 10px;
	margin-top: -1px;
}

ul.tabsRetirada div.containerOptionsRetirada li#selected {
	color: white;
}

.modalAlert {
	position: relative;
	display: flex;
	justify-content: center;
	padding: 10px;
	background: white;
	border-radius: 5px;
}

.modalAlert span {
	padding: 10px;
	min-width: 120px;
	max-width: 360px;
}

.modalAlert .closeBtn {
	background-color: transparent;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	padding: 5px;
	margin-top: 5px;
	margin-right: 5px;
}

@media (max-width: 1200px) {
    .cart {
        display: none;
    }
}

.buttons {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

.buttons button {
    position: relative;
    background: transparent;
    color: white;
    border: none;
    cursor: pointer;
    align-items: center;
}

.buttons button span {
    position: absolute;
    right: 0;
    display: flex;
    padding: 3px;
    width: 15px;
    height: 15px;
    color: white;
    font-size: 12px;
    border-radius: 5px;
    background: #ccc;
}

@keyframes spin {
	0% {transform: rotate(0deg)}
	100% {transform: rotate(720deg)}
}

div.categoryPage {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	max-width: 1124px;
	margin: auto;
}

aside.categoriesListing {
	width: 100%;
	display: flex;
	flex-direction: column;
	max-width: 320px;
}

aside.categoriesListing ul, aside.categoriesListing ul li {
	width: 100%;
	display: flex;
	flex-direction: column;
	list-style: none;
	margin: 0;
	padding: 0;
}

aside.categoriesListing ul {
	background: white;
	border: solid 1px #ccc;
}

aside.categoriesListing ul li a {
	width: 100%;
	display: grid;
	grid-template-columns: 50px auto;
	text-decoration: none;
	color: #444;
	padding: 8px 10px;
	background: white;
	grid-gap: 10px;
	align-items: center;
	font-size: 20px;
	word-wrap: break-word;
	line-break: loose;
	flex-wrap: wrap;
	overflow-wrap: break-word;
}

aside.categoriesListing ul li + li {
	border-top: solid 1px #ccc;
}

aside.categoriesListing ul li a:hover {
	-webkit-filter: brightness(95%);
	        filter: brightness(95%);
}

aside.categoriesListing ul li a img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: solid 1px #ccc;
}

.toogleCategories {
	display: none;
	align-items: center;
	font-size: 18pt;
	padding: 10px;
	cursor: pointer;
	border: solid 1px #ccc;
	background: white;
}

.toogleCategories svg {
	margin-right: 10px;
}

.bodyListItens {
	width: 100%;
	margin-left: 15px;
}

.bodyListItens ul {
	margin: 0;
	padding: 0;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 10px;
	list-style: none;
}

li.item {
    position: relative;
    min-height: 160px;
    display: flex;
    flex-direction: row;
    padding: 15px;
    border-radius: 5px;
    border: solid 1px #ccc;
    background-color: white;
    cursor: pointer;
    flex-flow: row;
    justify-content: stretch;
    padding-bottom: 70px;
}

.bodyListItens li:hover {
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
}

li.item h1 {
    font-size: 14pt;
}

li.item h2 {
    font-size: 14pt;
}


li.item .image {
    width: 60px;
    height: 60px;
}

li.item .image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

li.item .bodyItem {
    flex-grow: 1;
}

li.item button.add {
    right: 10px;
    cursor: pointer;
    margin: 0;
    padding: 10px;
    background-color: transparent;
    border: none;
}

li.item .image {
    margin-right: 15px;
    min-width: 60px !important;
}

li.item .footerItem {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    align-items: center;
}

div.bodyListItens div.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-column-start: 1;
	grid-column-end: 4;
}

div.bodyListItens div.loaderContainer .loader {
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 400px;
	border: solid 4px transparent;
	animation: spin 0.8s infinite ease-in-out;
}

div.bodyListItens div.loaderContainer .loader.hidden{
	display: none !important;
}

div.errorChargingItems {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-column-start: 1;
	grid-column-end: 4;
}

div.errorChargingItems svg {
	margin-right: 15px;
}

.footerItem p.estoque {
	margin-left: 10px;
}

@media (max-width: 1200px) {
	.bodyListItens ul {
		grid-template-columns: 1fr 1fr;
	}
}

@media (max-width: 900px) {
	div.categoryPage {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr;
		padding: 10px;
	}

	aside.categoriesListing {
		max-width: none;
		max-width: initial;
		transition: height 2s !important;
		margin-bottom: 20px;
	}

	.bodyListItens {
		margin: 0;
	}

	aside.categoriesListing ul {
		display: none;
	}

	aside.categoriesListing.active ul {
		display: flex;
	}

	.toogleCategories {
		display: flex;
	}

	.bodyListItens ul {
		grid-template-columns: 1fr;
	}
}

.containerSearch {
    width: 100%;
}

.containerSearch .header {
    justify-content: left;
    align-items: flex-start;
}

.containerSearch .header h1 {
    font-size: 16pt;
    text-align: left;
    margin: 10px;
    color: white;
}

.containerSearch .body {
    padding: 5px;
}

.containerSearch .body .listItensSearch {
    max-height: 80vh;
    overflow-y: scroll;
    width: 100%;
}

.listItensSearch .containerItem {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 10px;
    border: solid 1px #ccc;
    border-radius: 5px;
    margin: 10px;
}

.listItensSearch .containerItem h1 {
    font-size: 15pt;
}

.containerSearch input, .containerSearch select {
    width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
    border: solid 1px #ccc;
}

.containerSearch select {
    margin-top: 10px;
}

.containerItem div.notDisp {
	display: flex;
	color: #888;
	font-weight: 800;
}

.userModalBody{
    padding: 15px;
}
div#root {
    min-height: 100vh;
}

.ItemContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ItemContainer .item {
    position: relative;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    background: white;
    border: solid 1px #ccc;
    border-radius: 5px;
}

.ItemContainer .item h1 {
    font-size: 18pt;
    color: #888;
}

span {
    width: 100%;
    color: #888;
    font-size: 11pt;
    text-align: center;
}

div.spanGroup {
    display: flex;
    flex-direction: row;
}

span.order_num {
    color: #666;
    text-align: right;
    padding-top: 10px;
}

span.time_wait {
    color: #666;
    text-align: left;
    padding-top: 10px;
}

span.company_name_pedido {
    font-size: 14pt;
    font-weight: 900;
    text-align: center;
    margin-bottom: 10px;
}

table.itensPedidoFin{
    width: 100%;
    margin: 10px 0 !important;
}

div.item button.print {
    position: absolute;
    right: 0;
    top: 0;
    margin: 15px;
    border: none;
    cursor: pointer;
    background: transparent;
}
form {
    height: 100%;
}

.containerPgLogin {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.formLogin {
    width: 100%;
    max-width: 440px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    padding: 15px;
    background: white;
    border-radius: 5px;
    border: solid 1px #ccc;
}

.formLogin input {
    padding: 8px 10px;
    border-radius: 5px;
    border: solid 1px #ccc;
}

.formLogin label {
    font-weight: 900;
    color: #888;
}

.formLogin button.login {
    cursor: pointer;
    color: white;
    background: #4287f5;
    border: none;
    font-size: 14pt;
    margin-top: 10px;
}

.formLogin button.login:disabled {
    -webkit-filter: brightness(140%) saturate(40%);
            filter: brightness(140%) saturate(40%);
    cursor: default;
}

div.errorMsg {
    padding: 15px;
    background-color: #e22121;
    color: white;
}

.formLogin .logoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoContainer .logoImg {
    display: flex;
    width: 130px;
    height: 130px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
}

.logoContainer .logoImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.reCaptchaContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.dash {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    width: 100%;
    height: 100%;
}

.dashCounters {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
}

.dashCounters div.counter {
    display: grid;
    grid-template-columns: 1fr 90px;
    grid-gap: 15px;
    align-items: center;
    padding: 10px;
    color: white;
    font-size: 35pt;
    font-weight: bold;
    border-radius: 3px;
}

.dates {
    margin: 15px 0;
    display: grid;
    grid-template-columns: 80px 140px 30px 140px auto;
    text-align: center;
    align-items: center;
    vertical-align: middle;
}

.dates input {
    font-family: 'Roboto', sans-serif;
    padding: 5px 10px;
    border: solid 1px #ccc
}

.dashCounters div.counter img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    -webkit-filter: opacity(0.3);
            filter: opacity(0.3);
}

.counterPedidos {
    background-color: #20c1ed !important;
}

.counterAbertos {
    background: #18a55d !important;
}

.counterCancelados {
    background: #db6050 !important;
}

.counterFinalizados {
    background: #4287f5 !important;
}

.dashCounters div h1 {
    font-size: 25pt;
    text-transform: uppercase;
}

div.chart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 400px;
    margin-top: 80px;
}

div.chart canvas {
    width: 100%;
    height: 400px;
}

.pageCompleteWithMenu .headerMenuAdm {
	position: fixed;
	width: 100%;
	transition: 0.5s;
	z-index: 50 !important;
}

.pageCompleteWithMenu .content {
	transition: 0.5s;
}

.pageCompleteWithMenu aside.menu.active ~  .headerMenuAdm, .pageCompleteWithMenu aside.menu.active ~ .content {
	padding-left: 300px;
}

aside.menu {
	height: 100vh;
	position: fixed;
	font-size: 14pt;
}

aside.menu div.user {
	display: flex;
	align-items: center;
	padding: 5px;
	text-transform: uppercase;
	font-weight: bold;
	color: #888;
}

div.user div.imageUser {
	width: 70px;
	height: 70px;
	margin: 15px;
	border-radius: 50%;
	overflow: hidden;
}

div.imageUser img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

ul.menuToggleAdmin {
	display: flex;
	flex-direction: column;
	list-style: none;
	padding: 0;
	margin: 0;
	width: 100%;
	border: none;
	border-top: solid 1px #ccc;
	overflow-y: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
}


ul.menuToogleAdmin::-webkit-scrollbar {
	display: none;
}

ul.menuToggleAdmin li {
	display: flex;
	justify-content: left;
	align-items: center;
	width: 100%;
	border: none;
	border-bottom: solid 1px #ccc;
}

ul.menuToggleAdmin li a{
	display: flex;
	justify-content: left;
	align-items: center;
	width: 100%;
	padding: 15px;
	text-decoration: none;
	color: #888;
}

ul.menuToggleAdmin li a:hover {
	background: rgba(10, 10, 10, 0.04);
}

ul.menuToggleAdmin li a svg {
	margin-right: 18px;
}



div.pageCompleteWithMenu {
    display: flex;
    flex-direction: row;
}

div.pageCompleteWithMenu aside.menu {
    width: 0;
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;
    margin-left: -300px;
    transition: 0.5s;
    background: #e8e8e8;
}

div.pageCompleteWithMenu aside.menu.active {
    margin-left: 0;
}

aside.menu header {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #3a80a7;
    height: 60px;
}

aside.menu header img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    padding: 4px 10px;
}

div.pageCompleteWithMenu .content {
    width: 100%;
}

.admBody {
    flex-direction: column !important;
    max-width: none !important;
    max-width: initial !important;
    padding: 15px;
}

.containerAdmin {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    background: #408eba;
    height: 60px;
}

.openMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
}

.btnItemDash {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
    font-size: 12pt;
    text-decoration: none;
    color: white;
    padding: 8px 10px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.2);
    border: none;
}

.btnItemDash:hover {
    background: rgba(255, 255, 255, 0.5);
}

.tableContainer {
    width: 100%;
}

table.admItens {
    font-weight:300;
    border-collapse: collapse;
    top: 0;
    width: 100%;
    grid-gap: 0;
    gap: 0;
    border-spacing: 0;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

table.admItens thead{
    color: white;
    background: #408eba;
}

table.admItens td, table.admItens th {
    border-collapse: collapse;
    border: solid 1px #ccc;
    padding: 2px 10px;
}

table.admItens th {
    border: solid 1px #222276;
    font-weight: 900;
    font-size: 13pt;
}

table.admItens tbody tr {
    background: white;
}

table.admItens tbody tr:nth-child(2n){
    background: #d6d6d6;
}

table.admItens tbody tr:hover{
    background-color: #c8d4e2;
}

.statusIndicator{
    margin: auto;
    width: 15px;
    height: 15px;
    border-radius: 800px;
}

.statusIndicator.yellow {
    background: #fcba03;
}

.statusIndicator.red {
    background: red;
}

.statusIndicator.green {
    background: green;
}

.statusIndicator.cyan {
    background: cyan;
}

.statusIndicator.blue {
    background: #4287f5;
}

.printable {
    width: 100%;
    padding: 10px;
}
.printable table {
    width: 100%;
}

.printable h1 {
    font-size: 22px;
    font-weight: 600;
    color: #888;
    text-align: center;
}

table.admItens tbody td.checkbox input {
    display: block;
    margin: auto;
}

table.admItens tbody tr.selected{
    background-color: #c8d4e2;
}
.headerAdmPage{
    width: 100%;

}

.headerAdmPage h1 {
    font-size: 22px;
    font-weight: 600;
    color: #888;
    text-align: center;
    margin-right: 20px;
}

.headerAdmPage .utilityBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.utilityBar .search{
    display: flex;
    align-items: center;
}

.utilityBar .search h1 {
    cursor: auto;
    margin: 0;
    margin-right: 25px;
}

.utilityBar .search button {
    border: none;
}

.utilityBar .search input{
    width: 320px;
    padding: 8px 10px;
    border-radius: 8px;
    border: solid 1px #ccc;
}

.utilityBar .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    padding: 15px 0;
}

.utilityBar .buttons button.utilityBtns {
    padding: 8px 10px;
    cursor: pointer;
    background: #3a80a7;
    color: white;
    border: none;
}

button.utilityBtns {
    padding: 8px 10px;
    cursor: pointer;
    background: #3a80a7;
    color: white;
	border: none;
	border-radius: 5px;
}


.tblLegenda {
    display: flex;
    justify-content: right;
    align-items: center;
    vertical-align: middle;
    margin-bottom: 15px;
    grid-gap: 25px;
    gap: 25px;
}

.tblLegenda .legendaCtt{
    display: flex;
    flex-direction: row;
    grid-row-gap: 10px;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
}

.tblLegenda .statusIndicator {
    margin: 0 5px
}

div.body form {
    width: 100%;
}

div.body .bodySearch {
    width: 100%;
}

div.images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

div.images div.extraImage {
    position: relative;
    width: 100%;
    height: 120px;
    border-radius: 5px;
    overflow: hidden;
}

div.images div.extraImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

div.images div.extraImage label.imageLabel {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    bottom: 10px;
    right: 10px;
    background: #bbb;
    padding: 10px;
    color: white;
    border-radius: 5px;
    opacity: 0;
    transition: 0.5s;
    cursor: pointer;
}

#closeProductModal {
    margin-right: 10px !important;
}

div.images div.extraImage:hover label.imageLabel {
    opacity: 1;
}

div.images label.imageLabel:hover {
    background: #4287f5 !important;
}

input#extraImageInput1 {
    display: none;
}

input#extraImageInput2 {
    display: none;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(720deg);
    }
}

#modalProductCadForm {
    align-items: flex-start;
}

.modalProduct {
    max-width: 700px !important;
}

.containerAddForm {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 10px;
    padding: 15px;
    align-items: flex-start;
}

.containerAddForm label {
    height: 25px;
}

.inputFileHidden {
    display: none;
}

label.inputFileShowed {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    background: #c5c5c5;
    border-radius: 5px;
}

label.inputFileShowed.disabled {
    -webkit-filter: brightness(120%) contrast(90%);
            filter: brightness(120%) contrast(90%)
}

label.inputFileShowed.overImage{
    background: #c5c5c500;
    color: #ffffff00;
    position: absolute;
    top: 0;
    transition: 0.1s;
}

label.inputFileShowed.overImage svg {
    stroke: #ffffff00 !important;
}

label.inputFileShowed.overImage.hovered{
    background: #c5c5c5ff;
    color: #ffffffff;
}

label.inputFileShowed.overImage.hovered svg {
    stroke: #ffffffff !important;
}

.fotoContainer {
    position: relative;
    width: 150px;
    height: 150px;
}

.fotoContainer img {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

form.prodForm {
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.loaderProduct {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #50505050;
    z-index: 20;
}

.loadingContainer .loading, .loaderProduct .loader {
    width: 80px;
    height: 80px;
    border: 5px solid transparent;
    border-top: 5px solid white;
    border-bottom:  5px solid white;
    border-radius: 50%;
    animation: spin 0.6s ease-in-out infinite;
}

.seePicture {
    background: #4287f5;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 15px;
}

.deletePic {
    background: red;
    border: none;
    cursor: pointer;
    margin-top: 15px;
}

.logging {
    margin: 10px 0;
}

.logging button {
    width: 100%;
    cursor: pointer;
    background: #4287f5;
    color: white;
    border: none;
}

img.displayedImage {
    display: block;
}

.containerAddForm .input-form-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 8px;
    width: 100%;
    margin-top: 20px;
}

.containerAddForm .input-form-container input,.containerAddForm .input-form-container textarea, .containerAddForm .input-form-container .DraftEditor-root {
    padding: 8px 10px;
    width: 100%;
    border: solid 1px #ccc;
    border-radius: 5px;
}

.containerAddForm .input-form-container .DraftEditor-root {
    min-height: 120px;
    color: black !important;
    border-radius: 0;
}

.optionsEditorDraft {
    display: flex;
    padding: 5px;
    grid-gap: 5px;
    gap: 5px;
    border: solid 1px #ccc;
    border-bottom: none;
}

.optionsEditorDraft button {
    cursor: pointer;
}

.containerAddForm .input-form-container .DraftEditor-root span {
    color: black !important
}

.containerAddForm .input-form-container textarea {
    resize: vertical;
    font-family: 'Roboto', sans-serif;
    font-size: 10pt;
    min-height: 120px;
}

.containerAddForm .input-form-container .input-group {
    justify-content: start;
}

.containerAddForm .input-form-container div {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.spaced {
    grid-column-start: 1;
    grid-column-end: 5;
    width: 100%;
}

.divModalImage {
    position: relative;
    display: flex;
    max-width: 800px;
}

.divModalImage img {
    object-fit: cover;
    max-width: 100%;
}

.divModalImage .close {
    color: white !important;
    opacity: 100%;
}

.footerAddForm {
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.footerAddForm button {
    cursor: pointer;
    border: none;
    color: white
}

.footerAddForm button.cancel {
    background: red;
}

.footerAddForm button.save {
    background: green;
}

.containerAddForm .extraImages {
 grid-column-start: 1;
 grid-column-end: 3;
 display: flex;
 justify-content: space-between;
}

.extraImages .extraImage {
    min-width: 150px !important;
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 5px;
    overflow: hidden;
}

.extraImages .extraImage img {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.extraImages .extraImage img.displayedImage{
    display: flex;
}

#modalLog {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.bodyModalLog {
    padding: 10px;
}

.titleTableLog {
    background: #4287f5;
    color: white;
    font-size: 1.4em;
    padding: 10px;
}

.tableLog {
    width: 100%;
    padding: 10px;
    border: #ccc;
    border-spacing: 0;
    border-collapse: collapse;
}

.btnsItens {
    display: flex;
    justify-content: flex-end;
    grid-gap: 10px;
    gap: 10px;
}

.btnsItens button {
    color: white;
    background: #3a80a7;
    border: none;
    cursor: pointer;
}

.tableLog thead {
    background: #4287f5;
    color: white;
    padding: 0;
    border: #ccc;
    border-collapse: collapse;
}

.tableLog td, .tableLog th {
    padding: 5px 10px;
    border-collapse: collapse;
    border: solid 1px #ccc;
}

.headerComposition {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	margin-bottom: 10px;
	grid-gap: 10px;
	gap: 10px;
}

.headerComposition .minMax {
	display: grid;
	grid-template-columns: 80px 80px;
	grid-gap: 10px;
	gap: 10px;
	max-width: 220px;
}

.containerBody {
	max-height: 90vh;
	padding: 10px;
	overflow-y: scroll;
}

.headerItems {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    grid-gap: 10px;
    gap: 10px;
}

.headerItems .searchTerm {
    width: 60%;
    padding: 8px 10px;
    border-radius: 5px;
    border: solid 1px #ccc;
}

.headerItems .buttons {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

.headerItems .buttons button {
    background: #408eba;
    color: white;
}

h1.formLabel {
	padding: 10px;
	margin: 0;
	color: white;
	background: #408eba;
	font-size: 1.6em;
}

div.cadCompItem {
    display: grid;
    grid-template-columns: 150px 1fr;
    padding: 10px;
    grid-gap: 10px;
}
div.modal div.headerModal {
    background-color: #4287f5;
}
button.usefullBtn {
	cursor: pointer;
}

table .halfFilled {
	min-width: 50%;
}

th {
	position: relative;
	align-items: center;
}

th svg {
	position: absolute;
	right: 0;
	top: 8px;
}

td a.printIcon {
	display: flex;
	width: 100%;
	justify-content: center;
	color: #444;
}

td.phone a{
	color: black;
	text-decoration: none;
	padding: 1px 5px;
}

.containerDateTime {
	display: flex;
	width: 100%;
	justify-content: space-between;
	line-break: strict;
}

.containerDateTime .datetime {
	display: flex;
	grid-gap: 15px;
	gap: 15px;
	justify-content: flex-end;
}

.containerDateTime .datetime div {
	display: flex;
	line-break: strict;
	white-space: nowrap;
	justify-content: flex-end;
}

.filter {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	margin-top: -10px;
	margin-bottom: 15px;
}

.modal .bodySearch {
    padding: 20px;
    display: grid;
    grid-gap: 10px;
    gap: 10px;
}

.modal .bodySearch .spaced {
    grid-column-start: 1;
    grid-column-end: 3;
}

.modal .bodySearch input {
    padding: 8px 10px;
    border-radius: 5px;
    border: solid 1px #ccc;
    font-family: 'Roboto', sans-serif;
}
form.formScrollable {
	max-height: 90vh;
	width: 100%;
	height: 100% !important;
	overflow-y: scroll;
	margin-top: -55px;
	padding-top: 55px;
	z-index: 0;
}

.bodyPedido{
	padding: 10px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 10px;
	gap: 10px;
}

.bodyPedido .input-group{
	min-width: 0;
	justify-content: end;
}

.input-group .btnsPrints {
	display: flex;
	cursor: pointer;
	justify-content: center;
	border: solid 1px #ccc;
	color: #888;
	padding: 10px;
	word-break: keep-all;
	white-space: nowrap;
}

.input-group .btnsPrints svg {
	padding: 0;
	margin: 0;
	margin-right: 10px;
}

.bodyPedido input{
	padding: 10px;
	border-radius: 5px;
	border: solid 1px #ccc;
}

.bodyPedido input[type="date"] {
	font-family: 'Roboto', sans-serif;
	padding: 7.5px 10px !important;
}

.bodyPedido .nameSpaced {
	grid-column-start: 2;
	grid-column-end: 4;
	width: 100%;
}

.bodyPedido .spaced {
	grid-column-start: 1;
	grid-column-end: 4;
	width: 100%;
}

.paymentLine {
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-gap: 10px;
}

table td div.comp_itens {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr minmax(20px, 80px) minmax(20px, 80px) minmax(20px, 80px);
	padding: 5px;
}

div.Obs {
	margin-top: 10px;
	padding: 10px;
	border: solid 1px #ccc;
	border-radius: 5px;
	font-size: 15px;
	color: #888;
}

table td.qtdInput {
	padding: 0;
}

table td.qtdInput input {
	width: 100%;
	border-radius: 0 !important;
	background: transparent;
	border: none;
	font-family: 'Roboto', sans-serif;
	font-size: 1em;
	color: #000;
	font-weight: 300;
	cursor: text;
	-webkit-user-select: text;
	    -ms-user-select: text;
	        user-select: text;
}

table td.qtdInput input:disabled {
	cursor: default;
	-ms-user-select: none;
	    user-select: none;
	-webkit-user-select: none;
}

table td div.comp_itens span {
	color: #555;
	font-size: 14px;
}

table td div.comp_itens span.left {
	text-align: left;
	padding: 0;
	margin: 0;
}

table td div.comp_itens span.right {
	margin: 0;
	padding: 0;
	text-align: right;
}


.configBody {
    display: flex;
    flex-direction: column !important;
    width: 100%;
    height: 100%;
    justify-content: center !important;
    align-items: center!important;
}

.configBody .config {
    width: 100%;
    padding: 20px;
    background: white;
    border-radius: 5px;
    border: solid 1px #ccc;
}

.config .header {
    margin-top: -20px;
}

.config .banner {
    height: 200px;
    position: relative;
    margin-bottom: 40px;
}

.config h1 {
    margin-right: 0;
}

.config .banner .logo {
    position: relative;
    margin: auto !important;
    border: none;
    margin: 0
}

.config .selector {
	margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.config .selector select {
	width: 90%;
	margin: 0;
}

.config .selector .indicator {
    width: 20px;
    height: 20px;
    margin: 10px;
    padding: 0;
    border-radius: 50%;
}

.banner .logoImageFixed {
    position: absolute;
    width: 100%;
    bottom: -15px;
}

.logo label#imageLogoLabel {
    opacity: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    background: #bbb;
    transition: 0.5s;
}

.banner label#imageBannerLabel {
    display: flex;
    justify-content: center;
    opacity: 0;
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
    padding: 10px;
    background: #bbb;
    color: white;
    border-radius: 5px;
    grid-gap: 10px;
    gap: 10px;
    transition: 0.5s;
}

.banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner:hover label#imageBannerLabel{
    opacity: 100;
}

.banner:hover label#imageBannerLabel:hover {
    background-color: #4287f5;
}

.logo label#imageLogoLabel:hover {
    opacity: 100;
}

.logo input#imageLogoInput,.banner #imageBannerInput {
    display: none;
}

.config .configForm{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
	grid-gap: 10px;
	align-items: flex-end;
}

.configForm .inputLarge {
    grid-column-start: 1;
    grid-column-end: 4;
}

.inputLarge textarea {
    width: 100%;
    resize: vertical;
    font-family: 'Roboto', sans-serif;
    margin-top: 10px;
}

.configForm input {
    font-family: 'Roboto', sans-serif;
    padding: 8px 10px;
    border-radius: 5px;
    border: solid 1px #ccc
}

.input-group .inputs {
    display: grid;
    grid-template-columns: 5fr 1fr 5fr;
    grid-gap: 10px;
    align-items: center;
    text-align: center;
}

.footerForm {
    grid-column-start: 1;
    grid-column-end: 5;
    display: flex;
    flex-direction: row-reverse;
}

.footerForm .save {
    display: flex;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    border: none;
    background: #4287f5;
    font-size: 12pt;
    color: white;
    cursor: pointer;
    transition: 0.5s;
}

.footerForm .save:hover {
    -webkit-filter: brightness(140%);
            filter: brightness(140%);
}

label#disabled {
    color: #888;
}

footer.footerVersion {
    display: flex;
    bottom: 0;
    padding: 5px 10px;
    color: #888
}


table.admItens button.deleteBtn {
    cursor: pointer;
    border: none;
    padding: 2px;
    background: transparent;
    border-radius: 50%;
}
.modal .headerCategory {
    padding: 10px;
    color: white;
}

.modal .headerCategory h1 {
    width: 100%;
    font-size: 16pt;
    padding: 0px;
    margin: 0px;
}

.inputFileHidden {
    display: none;
}

label.inputFileShowed {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    background: #c5c5c5;
    border-radius: 5px;
}

label.inputFileShowed.overImage{
    background: #c5c5c500;
    color: #ffffff00;
    position: absolute;
    top: 0;
    transition: 0.1s;
}

label.inputFileShowed.overImage svg {
    stroke: #ffffff00 !important;
}

label.inputFileShowed.overImage.hovered{
    background: #c5c5c5ff;
    color: #ffffffff;
}

label.inputFileShowed.overImage.hovered svg {
    stroke: #ffffffff !important;
}

.left {
    margin: 10px;
}

.left .imgContainer{
    position: relative;
    width: 150px;
    height: 150px;
    overflow: hidden;
}

.imgContainer img {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

img.displayedImage {
    display: block;
}

.modal .formCategory{
    width: 100%;
    padding: 10px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 10px;
}

.input-group {
    justify-content: flex-end;
}

.modal .formCategory input {
    padding: 9px 10px;
    border: solid 1px #ccc;
    border-radius: 5px;
}

.modal .formCategory label {
    margin-bottom: 10px;
}

.containerFormCategory {
    display: flex;
    justify-content: stretch;
}
div.modal div.headerModal {
    background-color: #4287f5;
}
th.eightFilled {
    width: 80%;
}
.regionsTableContainer {
    padding: 10px;
}

.headerRegions {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.headerRegions h1 {
    font-size: 22px;
    color: #888;
    margin: 0;
    padding: 0;
}

.headerRegions .buttonsRegions {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

.cadCity {
	display: flex;
	flex-direction: column;
	max-height: 500px;
	overflow-y: scroll;
}

.headerRegions .buttonsRegions button {
    color: white;
    background: #3a80a7;
    cursor: pointer;
    border: none;
    padding: 8px 12px;
}

.cepBtn {
	margin-top: 15px;
	cursor: pointer;
	color: white;
	background: #3a80a7;
}

.formCEP .input-group {
	padding: 10px;
}

.formPaymentMethod {
	display: grid;
	grid-template-columns: 1fr 120px;
	margin: 10px;
	grid-gap: 10px;
	gap: 10px;
}

.formPaymentMethod input {
    padding: 10px;
    border: solid 1px #ccc;
    border-radius: 5px;
}

.formCadEntregador{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 10px;
    grid-gap: 10px;
}

.formCadEntregador input {
    padding: 10px;
    border: solid 1px #ccc;
    border-radius: 5px;
}

.formCadEntregador textarea {
    font-family: 'Roboto', sans-serif;
    resize: vertical;
}

.formCadEntregador .fullsized {
    grid-column-start: 1;
    grid-column-end: 4;
}

.formCadEntregador .input-group {
    justify-content: space-between;
}

div.modal div.headerCategory {
    background-color: #4287f5;
}
.bodySearch .dateInputs{
    display: grid;
    grid-template-columns: 1fr 30px 1fr;
    grid-gap: 10px;
    justify-content: center;
    text-align: center;
    align-items: baseline;
}

.bodySearch .splitContainer input {
    width: 100%;
}

label {
    text-align: left;
    text-align: initial;
}

div.modal div.headerModal {
    background-color: #4287f5;
}


h1#titleClient {
    padding: 10px;
    margin: 0;
    background-color: #4287f5;
    font-size: 25px;
    color: white;
}

.clientBody {
    padding: 10px;
}

.input-group {
    margin-top: 10px;
}

.input-group input, .input-group select {
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #ccc;
}

.buttonsModal {
    display: flex;
    justify-content: space-between;
}

.saveBtn {
    padding: 8px 10px;
    margin: 10px;
    cursor: pointer;
    color: white;
    background: green;
}

.cancelBtn {
    padding: 8px 10px;
    margin: 10px;
    cursor: pointer;
    color: white;
    background: red;
}


.group-line {
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-gap: 10px;
}

div.loaderModal {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background: #50505050;
	z-index: 80;
}

div.loaderModal .spinner {
	width: 85px;
	height: 85px;
	border: 5px solid transparent;
	border-radius: 50%;
	border-top: 5px solid white;
	border-bottom: 5px solid white;
    animation: spin 0.6s ease-in-out infinite;
}

h1#titleGroup {
    background: #4287f5;
    padding: 10px;
    margin: 0;
    font-size: 25px;
    color: white;
}

.groupBody {
    padding: 10px;
    display: grid;
    align-items: end;
    grid-template-columns: 1fr 110px 85px;
    grid-gap: 10px;
    gap: 10px;
}
.day_of_wek_searcher {
	padding: 8px 10px;
	border-radius: 5px;
	background: white;
	border: solid 1px #ccc
}

form.inputOpening {
	padding: 10px;
}

.intervalDate {
	display: flex;
	grid-gap: 10px;
	gap: 10px;
}

div.loaderModal {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background: #50505050;
	z-index: 80;
}

div.loaderModal .spinner {
	width: 85px;
	height: 85px;
	border: 5px solid transparent;
	border-radius: 50%;
	border-top: 5px solid white;
	border-bottom: 5px solid white;
    animation: spin 0.6s ease-in-out infinite;
}


.hollydayLine {
	display: grid;
	grid-template-columns: minmax(180px, 520px) minmax(60px, 120px);
	grid-gap: 10px;
}

.containerRecover {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.containerRecover .formRecover {
	min-width: 400px;
	padding: 10px;
	background: white;
	border: solid 1px #ccc;
	border-radius: 5px;
	box-shadow: 0 0 30px #888;
}

.containerRecover .formRecover button {
	width: 100%;
	font-size: 1.2em;
	padding: 10px;
	margin-top: 10px;
	cursor: pointer;
	background: #888;
	color: white;
	font-weight: 500;
}

h1.recoverPass {
	font-size: 1.8em;
	color: #888;
	font-weight: 700;
	padding: 0;
	margin: 0;
}

