.containerRecover {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.containerRecover .formRecover {
	min-width: 400px;
	padding: 10px;
	background: white;
	border: solid 1px #ccc;
	border-radius: 5px;
	box-shadow: 0 0 30px #888;
}

.containerRecover .formRecover button {
	width: 100%;
	font-size: 1.2em;
	padding: 10px;
	margin-top: 10px;
	cursor: pointer;
	background: #888;
	color: white;
	font-weight: 500;
}

h1.recoverPass {
	font-size: 1.8em;
	color: #888;
	font-weight: 700;
	padding: 0;
	margin: 0;
}
